:root {
  /*BACKGROUND COLORS*/
  --clr-bg-black: #000;
  --clr-bg-white: #fff;

  /*CARD COLORS*/
  --clr-card-gray: #1a1a1a;
  --clr-card-black: #000;
  --clr-card-white: #fff;

  /*LETTERMARK LOGO COLORS*/
  --clr-logo-blue: #2871ff;
  --clr-logo-white: #fff;

  /*LETTERMARK LOGO FONT SIZES & WEIGHT*/
  --fs-logo-l: clamp(1rem, 8vw, 2rem);
  --fs-logo-s: clamp(0.8rem, 6vw, 1.5rem);
  --fw-logo-thin: 400;
  --fw-logo-xbold: 900;

  /*FONT COLORS*/
  --clr-text-white: #fff;
  --clr-text-black: #000;
  --clr-text-red: #fd715cf2;

  /*TEXT GRADIENTS*/
  --gradient-white-red: linear-gradient(to right, #ffff, #ff0000);
  --gradient-blue-purple: linear-gradient(to right, #4ae6fc, #a640ff);
  --gradient-white-blue: linear-gradient(to right, #ffff, #0066ff);
  --gradient-white-yellow: linear-gradient(to right, #ffff, #ffe500);
  --gradient-black-orange: linear-gradient(to right, #000000, #ff6d05);
  --gradient-white-yellow-red: linear-gradient(
    to right,
    #ffffff,
    #caa541,
    #c35454
  );
  --gradient-white-purple-blue: linear-gradient(
    to right,
    #ffffff,
    #7c49db,
    #304abd
  );

  --gradient-testimonials: linear-gradient(
    to right,
    #bffc9e,
    #4b51fc,
    #ffab51,
    #ff5151
  );

  /*CARD GRADIENTS*/

  --gradient-purple-blue: linear-gradient(170deg, #5b247a, #1bcedf);
  --gradient-orange-pink: linear-gradient(170deg, #f36265, #961276);
  --gradient-black-green: linear-gradient(170deg, #184e68, #57ca85);
  --gradient-yellow-orange: linear-gradient(170deg, #fad961, #f76b1c);

  /*FONT SIZES*/
  --fs-xxl: min(6rem, 5vw);
  --fs-xl: clamp(1rem, 6vw, 69px);
  --fs-xl-v2: clamp(4rem, 5vw, 69px);
  --fs-xl-v3: clamp(3rem, 5vw, 69px);
  --fs-l: clamp(1.8rem, 5vw, 3rem);
  --fs-l-v3: clamp(2.2rem, 7vw, 3rem);
  --fs-l-v2: clamp(2rem, 2.5vw, 3rem);
  --fs-m: clamp(1.3rem, 3vw, 1.5rem);
  --fs-m-v2: clamp(1rem, 1.5vw, 1.2rem);
  --fs-m-v3: clamp(1.3rem, 7vw, 1.9rem);
  --fs-s: clamp(0.8rem, 4vw, 1rem);
  --fs-xs: clamp(0.6rem, 2vw, 0.8rem);

  /*FONT WEIGHTS*/
  --fw-thin: 100;
  --fw-mid: 500;
  --fw-bold: 800;
  --fw-xbold: 900;

  /*BUTTONS STYLES*/
  --fw-btn-400: 400;
  --fw-btn-600: 600;
  --clr-btn-white: #fff;
  --clr-btn-black: #000;
}

.thin {
  font-weight: var(--fw-thin);
}

.bold {
  font-weight: var(--fw-xbold);
}

.nowrap {
  white-space: nowrap;
}
