.questions {
  border-bottom: solid 1px black;
  width: 100%;
  padding-bottom: 2rem;
}

.questions:last-child {
  border: none;
}

.questions:nth-child(7) {
  border: none;
}

.questions > h3 {
  margin-bottom: 0.5rem;
}
