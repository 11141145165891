.tab > img {
  width: 20px;
}

.tab-title {
  font-weight: 300;
  font-size: var(--fs-s);
}

@media (max-width: 768px) {
  .tab > img {
    display: none;
  }
}
