:root {
  --green: #7ac142;
  --white: #fff;
  --curve: cubic-bezier(0.65, 0, 0.45, 1);
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: var(--white);
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px var(--green);
  animation:
    fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--green);
  fill: none;
  animation: stroke 0.6s var(--curve) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s var(--curve) 0.8s forwards;
}

.form-checkmark {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px var(--green);
  }
}
