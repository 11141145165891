#form {
  background-color: var(--clr-bg-black);
  color: var(--clr-text-white);
  padding: 96px clamp(1rem, 1vw, 198px);
}

.form-content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.form-left {
  width: 40%;
}

.form-left-title {
  font-size: var(--fs-xl-v3);
  font-weight: var(--fw-thin);
  margin-bottom: 20px;
  white-space: nowrap;
}

.bold {
  font-weight: var(--fw-bold);
}

.form-left-para {
  font-size: var(--fs-s);
  margin-bottom: 54px;
}

.form-left-subtitle {
  font-size: var(--fs-m);
  font-weight: var(--fw-mid);
  margin-bottom: 20px;
}

.form-left-subtitle-special {
  margin-top: 54px;
}

.form-left-email,
.form-left-link {
  font-size: var(--fs-s);
  color: var(--clr-text-white);
  list-style-type: none;
}

.form-left-email {
  text-decoration: none;
}

.form-left-link {
  margin-bottom: 20px;
}

.form-left-link-anchor {
  text-decoration: underline;
  color: var(--clr-text-white);
}

.form-right {
  width: 50%;
  height: 100%;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 0.3s;
}

.formInput {
  border-radius: 0;
  padding: 0.7rem;
}

:where(.css-dev-only-do-not-override-18iikkb).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  height: auto;
  padding: 0.6rem 11px;
  border-radius: 0;
}

label {
  font-size: var(--fs-s);
}

.ant-input {
  border-radius: 0;
}

.formButton,
.ant-btn {
  background-color: white;
  padding: 1rem;
  height: auto;
  transition: all 300ms ease;
  border-radius: 0;
  border: 4px solid transparent;
}

.formButton:hover {
  cursor: pointer;
  background-color: transparent;

  border-color: var(--clr-logo-blue);
  color: var(--clr-text-white);
}

.service-selector {
  width: 100%;
}

/* RESPONSIVENESS */
/* 768px: Tablets */
@media (max-width: 768px) {
  .form-content {
    flex-direction: column;
  }
  .form-left,
  .form-right {
    width: 100%;
  }
  .form-left {
    margin-bottom: 54px;
  }
}
.logo{
  cursor: pointer;
}
