.strip {
  width: 100%;
  height: 120px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all;
  background-size: cover;
  background-image: url("../Assets/strip.png");
  background-repeat: repeat-x;
}

.strip > img {
  width: min(150px, 230vw);
  animation: 40s moveRight linear infinite;
}

@keyframes moveRight {
  from {
    transform: translateX(-1100%);
  }

  to {
    transform: translateX(1100%);
  }
}

@media (max-width: 800px) {
  .strip {
    gap: 150px;
  }

  .strip > img {
    animation: 5s moveRight linear infinite;
  }

  @keyframes moveRight {
    from {
      transform: translateX(-700%);
    }

    to {
      transform: translateX(700%);
    }
  }
}

@media (max-width: 768px) {
  .strip {
    gap: 100px;
  }

  .strip > img {
    animation: 5s moveRight linear infinite;
  }

  @keyframes moveRight {
    from {
      transform: translateX(-600%);
    }

    to {
      transform: translateX(600%);
    }
  }
}
