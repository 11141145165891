.fixed-navbar {
  position: fixed;
  max-width: 1080px;
  background-color: rgba(51, 51, 51, 1);
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border-radius: 0.8rem;
  animation: fadein 0.2s forwards linear;
}

.fixed-content {
  align-self: flex-end;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
}

.fixed-button {
  padding: 0.5rem 0.89rem;
  background-color: transparent;
  border: 0.6rem solid #444444;
  border-radius: 0.8rem;
  color: white;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 500;
  max-width: 200px;
  font-weight: 500;
  width: 100%;

  &:hover {
    background-color: #161616;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
}

.fixed-CTA {
  padding: 0.5rem 2rem;
  background-color: var(--clr-logo-blue);
  border: none;
  border-radius: 0.8rem;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all 0.3s;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  &:hover {
    background-color: #161616;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
}

.fixed-logo {
  max-width: 50px;
  transition: transform 0.3s;

  &:hover {
    cursor: grab;
    transform: scale(1.2);
  }
}

.fixed-burger {
  position: fixed;
  bottom: 2rem;
  right: 1.5rem;
  z-index: 1001;
  background-color: #333333;
  border-radius: 50%;
  padding: 0.5rem;
  display: none;
}

.fixed-burger-open {
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
  z-index: 2000;
  padding: 1.3rem;

  max-height: 85px;
  border-radius: 0.8rem;
  background-color: #444444;
}

.hide-nav {
  display: none;
}

.show-nav {
  display: block;
}

.fixed-link {
  width: 100%;
}

.fixed-cta-wrapper {
  display: flex;
  width: 100%;
}

@media (max-width: 900px) {
  .fixed-navbar {
    transform: translate(0%, 0%);
    left: 0;
    bottom: 0;
    border-radius: 0;
    padding: 0;
    background-color: rgba(51, 51, 51, 0.9);
  }
  .fixed-content {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    gap: 1rem;
    padding: 1rem 0rem;
    align-items: center;
  }

  .fixed-logo {
    max-width: 100px;
  }
  .fixed-link {
    height: 100%;
  }

  .fixed-link:last-child {
    height: auto;
  }

  .fixed-button {
    border: none;
    max-width: none;
    font-size: 2rem;
    text-align: start;
    padding-left: 2rem;
    border-radius: 3px;
    height: 100%;

    &:hover {
      background-color: #161616;
      color: rgb(255, 255, 255);
      cursor: pointer;
    }
  }

  .fixed-CTA {
    height: 85px;
    margin-left: 1.5rem;
    width: calc(100% - 160px);
  }

  .fixed-burger {
    display: block;
  }
}
