.addons-container {
  color: var(--clr-text-black);
  display: flex;
  margin: 50px 0;
  flex-direction: column;
  gap: 3rem;
  position: relative;
}

.addons-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.addons-header > h1 {
  font-size: var(--fs-l);
}

.addons-header > p {
  font-size: var(--fs-s);
  width: clamp(350px, 30vw, 650px);
}

.addons {
  display: grid;
  place-items: center;
  width: fit-content;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
}

@media (max-width: 1260px) {
  .addons > div:nth-child(3) {
    grid-column: span 2;
  }

  .addons {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    gap: 2rem;
  }
}

@media (max-width: 950px) {
  .addons-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .addons-header > p {
    text-align: center;
    width: 350px;
  }
}

@media (max-width: 768px) {
  .addons > div:nth-child(3) {
    grid-column: span 1;
  }

  .addons {
    grid-template-columns: repeat(1, minmax(auto, 1fr));
    gap: 2rem;
    width: 100%;
  }
}
