.about-tab {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5rem;
}

.about-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.about-header > p {
  width: fit-content;
  background: var(--gradient-white-red);
  -webkit-background-clip: text;
  font-size: var(--fs-s);
  -webkit-text-fill-color: transparent;
}

.about-header > h1 {
  font-size: var(--fs-m);
}

.about-content > p {
  font-size: var(--fs-s);
  line-height: 1.5rem;
}

.about-img {
  width: 60%;
}

.about-img > img {
  width: 100%;
  min-width: 300px;
  margin-bottom: -10px;
}

.about-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.about-content > div > h2 {
  text-transform: uppercase;
  font-weight: var(--fw-bold);
}

.aboutus-tabs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 0 1rem 0 1rem;
}

@media (max-width: 940px) {
  .about-tab {
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }

  .about-content {
    align-items: center;
  }

  .about-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .about-content > p {
    text-align: center;
    width: 300px;
  }

  .about-img {
    width: 100%;
  }
}
