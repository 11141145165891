@import url("https://fonts.googleapis.com/css2?family=Anton&family=Instrument+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Playfair+Display:ital,wght@0,400;0,500;1,400&family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed&display=swap");
/* font family */
/* font-family: 'Red Hat Display', sans-serif; */

.hero {
  background: rgb(24, 24, 24);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(23, 0, 0, 1) 17%,
    rgba(3, 3, 40, 1) 50%,
    rgba(0, 2, 24, 1) 83%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hero .HeroTitleh1 {
  width: 1000px;
  font-size: var(--fs-xl);
  color: var(--clr-text-white);
  text-align: center;
}
.heroBtns {
  display: flex;
  gap: 10px;
}
.logo > h1,
.logo {
  color: var(--clr-logo-white);
  font-size: var(--fs-logo-l);
  font-weight: var(--fw-logo-xbold);
}

.logo > h1 > span,
.logo > span {
  color: var(--clr-logo-blue);
  font-size: var(--fs-logo-s);
  font-weight: var(--fw-logo-thin);
}

.HeroHeadingP {
  color: var(--clr-text-white);
  width: min(660px, 90%);
  text-align: center;
  font-size: var(--fs-s);
}
.HeroTextContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.HeroBigWord {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 21px;
}

@keyframes wordLeft {
  from {
    margin-left: -210px;
  }
  to {
    margin-left: 0px;
  }
}

@keyframes wordRight {
  from {
    margin-right: -210px;
  }
  to {
    margin-right: -35px;
  }
}

.BigWordWire {
  background: -webkit-linear-gradient(hsla(0, 0%, 100%, 1), transparent);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: var(--fs-xxl);
  letter-spacing: 0.4em;
  margin-right: -35px;
  animation: wordRight 1s;
}

.hero-W {
  rotate: 30deg;
}

.BigWordFlow {
  background: -webkit-linear-gradient(hsla(0, 0%, 100%, 1) 0%, transparent);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: var(--fs-xxl);
  letter-spacing: 0.4em;
  animation: wordLeft 1s;
}

.heroPhone {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(100%);
  animation: phoneUp 1.5s forwards;
  animation-delay: 0.5s;
  clip-path: stroke-box(100%, 0);
}

.heroPhone > img {
  max-width: 429px;
  min-width: 200px;
  width: min(100%, 500px);
  overflow: hidden;
}

@keyframes phoneUp {
  from {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0%);
  }
}

.heroWords {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: min(115px, 5vw);
  transition: all 0.3s;
}
.heroBookButton {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: var(--clr-btn-white);
  border: none;
}

.ContactUsBtn {
  border-radius: 0.5rem;
  background-color: var(--clr-btn-white);
  border: none;
  font-size: var(--fs-s);
  cursor: pointer;
  transition: all 0.3s;
  font-weight: var(--fw-btn-400);
  overflow: hidden;
  padding: 0.7rem 1.6rem;
  border: 2px solid transparent;
}

.ContactUsBtn:hover {
  border: 2px solid white;
  background-color: transparent;
  color: white;
}

.BookCallLink {
  text-decoration: none;
  color: var(--clr-btn-white);
  background-color: transparent;
  border: none;
  font-size: var(--fs-s);
  cursor: pointer;
  transition: all 0.3s;
  font-weight: var(--fw-btn-400);
  white-space: nowrap;
  border: 2px solid white;
  padding: 0.6rem 1.6rem;
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.BookCallLink:hover {
  background-color: white;
  color: black;
}

.FullHeroContent {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 1rem;
}

@media (max-width: 1400px) {
  .BigWordWire {
    margin-right: -20px;
  }
}

@media (max-width: 900px) {
  .BigWordWire {
    margin-right: -15px;
  }
}

@media (max-width: 768px) {
  .hero {
    background: linear-gradient(
      -180deg,
      rgba(3, 3, 40, 1) 50%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .navBtn {
    display: none;
  }

  .FullHeroContent {
    margin-top: 50px;
    gap: 4rem;
  }

  .BigWordWire,
  .BigWordFlow {
    display: none;
  }

  .heroWords {
    margin-left: 0;
  }

  .hideText {
    display: none;
  }

  .heroPhone {
    animation: phoneUp 1s forwards;
    animation-delay: 0.2s;
  }
}
