.FAQ-Content {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2.5rem;
  column-gap: 4rem;
  padding-bottom: 2rem;
}

.FAQ-category {
  background-color: rgba(13, 42, 148, 0.5);
}

.FAQ-answers {
  background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 1024px) {
  .FAQ-Content {
    grid-template-columns: 1fr;
  }
}
