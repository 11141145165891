.testimonial-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 1rem;
  min-width: 300px;
  width: 400px;
  min-height: 300px;
  max-width: 400px;
  background-color: var(--clr-card-white);
  color: var(--clr-text-black);
  z-index: 2;
  padding: 2rem;
  border-radius: 3px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
}

.testimonial-profile {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-self: center;
}

.testimonial-info > h3 {
  font-size: var(--fs-m-v2);
}

.testimonial-info > h4 {
  font-size: calc(var(--fs-s) - 0.1rem);
  opacity: 0.7;
}

.testimonial-img {
  margin-bottom: -5px;
}
