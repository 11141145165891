.question-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: clamp(300px, 100%, 650px);
  background-color: var(--clr-card-white);
  color: var(--clr-text-black);
  padding: 2rem;
  border-radius: 3px;
  border: 1px solid var(--clr-card-gray);
  cursor: pointer;
  transition: all 0.3s;
}

.question > h3 {
  font-size: var(--fs-m-v2);
}

.answer > p {
  font-size: var(--fs-s);
}

.question-card:hover {
  box-shadow: 0 0px 75px 0 rgba(0, 21, 255, 0.5),
    25px 0px 75px 0px rgba(248, 2, 2, 0.5),
    0px 0px 75px 25px rgba(2, 164, 29, 0.32);
}
