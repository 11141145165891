.testimonial-card-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 1rem;
  width: 400px;
  min-height: 300px;
  max-width: 400px;
  background-color: var(--clr-card-white);
  color: var(--clr-text-black);
  z-index: 2;
  padding: 2rem;
  border-radius: 3px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
}

.testimonial-stars-skeleton {
  display: flex;
  gap: 0.3rem;
}

.testimonial-stars-skeleton > * {
  height: 1.2rem;
  border-radius: 50%;
  width: 1.1rem;
  animation: colorChange 2000ms infinite linear;
}

.testimonial-profile-skeleton {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-self: center;
}

.testimonial-review-skeleton {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.testimonial-review-skeleton > *:nth-child(odd) {
  height: 1.2rem;
  border-radius: 1rem;
  width: 200px;
  animation: colorChange 2000ms infinite linear;
}

.testimonial-review-skeleton > *:nth-child(even) {
  height: 1.2rem;
  border-radius: 1rem;
  width: 120px;
  animation: colorChange 2000ms infinite linear;
}

.testimonial-info-skeleton > h3 {
  height: 1.2rem;
  border-radius: 1rem;
  width: 75px;
  animation: colorChange 2000ms infinite linear;
}

.testimonial-info-skeleton > h4 {
  height: 1.2rem;
  border-radius: 1rem;
  width: 120px;
  opacity: 0.7;
  animation: colorChange 2000ms infinite linear;
}

.testimonial-info-skeleton {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.testimonial-img-skeleton {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  animation: colorChange 2000ms infinite linear;
}

@keyframes colorChange {
  0% {
    background-color: rgb(153, 153, 153);
    opacity: 0.4;
  }

  50% {
    background-color: rgb(153, 153, 153);
    opacity: 0.5;
  }
  75% {
    background-color: rgb(153, 153, 153);
    opacity: 0.7;
  }
  100% {
    background-color: rgb(153, 153, 153);
    opacity: 0.3;
  }
}
