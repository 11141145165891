.innovation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 50px;
}

.innovation-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  width: min(570px, 100%);
}

.innovation-content > p {
  font-size: var(--fs-s);
}

.innovation-header {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innovation-header > p {
  width: fit-content;
  font-size: var(--fs-s);
  background: var(--gradient-white-blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.innovation-header > h1 {
  font-size: var(--fs-m);
}
