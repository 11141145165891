.Navbar {
  display: flex;
  justify-content: space-between;
  padding: 0 calc(1rem + 4.5vw);
  padding-top: 20px;
  align-items: center;
}

.navBtn {
  margin-left: 24px;
}

.contactInteractions {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media (max-width: 768px) {
  .Navbar {
    padding: 0 1rem;
    padding-top: 20px;
  }
}
