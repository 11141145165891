.faq-container {
  background-color: var(--clr-bg-black);
  color: var(--clr-text-white);
  padding: 75px 50px;
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  justify-content: start;
  place-items: center;
  width: fit-content;
  gap: 2rem;
  width: 100%;
}

.faq-header > h4 {
  background: var(--gradient-white-red);
  background-clip: text;
  color: transparent;
  width: fit-content;
  font-size: var(--fs-m-v2);
  font-weight: var(--fw-mid);
}

.faq-header > h2 {
  font-size: var(--fs-l);
  white-space: nowrap;
}

.faq-header > p {
  margin-top: 1rem;
  width: fit-content;
}

.faq-header {
  align-self: flex-start;
  width: 70%;
}

.faq-questions {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 4rem;
  width: fit-content;
  width: 100%;
}

.faq-questions > *:first-of-type {
  box-shadow:
    0 0px 75px 0 rgba(0, 21, 255, 0.5),
    25px 0px 75px 0px rgba(248, 2, 2, 0.5),
    0px 0px 75px 25px rgba(2, 164, 29, 0.32);
}

.faq-more {
  margin-top: -1rem;
}

.faq-more > a {
  color: white;
  text-decoration: none;
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  border-bottom: 2px solid white;
  transition: all 0.3s;
}

.faq-more > a:hover {
  color: var(--clr-logo-blue);
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

@media (max-width: 1083px) {
  .faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 75px 1rem;
  }

  .faq-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .faq-questions {
    align-items: center;
    gap: 2rem;
  }

  .faq-questions > *:nth-of-type() {
    width: 100%;
  }

  .faq-header > p {
    margin-top: 1rem;
    width: fit-content;
    text-align: center;
    width: 80%;
  }
}
.no-scroll {
  overflow: hidden;
}
