@import url("https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap");

.mission-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  padding-bottom: 50px;
}

.mission-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mission-header > p {
  width: fit-content;
  font-size: var(--fs-s);
  background: var(--gradient-white-yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mission-header > h1 {
  font-size: var(--fs-m);
}

.mission-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.mission-images {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.mission-content > p {
  font-size: var(--fs-m);

  width: clamp(300px, 75vw, 1200px);
  font-weight: var(--fw-thin);
}

.quotes {
  font-family: "RocknRoll One", sans-serif;
  font-size: 3rem;
}
