@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.done-container {
  position: relative;
  background-color: var(--clr-bg-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0 3rem 0;
  gap: 3rem;
  color: var(--clr-text-black);
}

.done-header > h1 {
  white-space: nowrap;
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  letter-spacing: 0.45px;
}

.done-header {
  text-align: center;
}

.done-sections {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: clamp(50px, 2vw, 62px);
}

.done-col {
  display: flex;
  gap: 1.5rem;
  width: min(338px, 90%);
}

.section-icon > img {
  width: auto;
  color: var(--clr-text-black);
  padding: 5px;
}

.section-content {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.section-content > h1 {
  font-size: var(--fs-l-v2);
}

.section-content > h3 {
  font-size: var(--fs-m-v2);
}

.section-content > p {
  font-size: var(--fs-s);
}
