#services {
  background-color: var(--clr-bg-black);
  background: url("../Assets/tech-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.services-container {
  color: var(--clr-text-white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 300px;
  padding: 100px 3rem;
}

.service-boxes {
  display: flex;
  align-items: center;
  gap: 50px;
}

.service-box1 {
  padding: clamp(2rem, 5vw, 3rem) clamp(2rem, 5vw, 3rem) clamp(2rem, 5vw, 3rem)
    clamp(2rem, 5vw, 3rem);
  width: min(380px, 90vw);
  background-color: var(--clr-card-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 7px;
}

.macbook-container {
  margin-bottom: -75px;
}

.phone-container {
  margin-bottom: -250px;
}

.macbook-mockup,
.phone-mockup {
  width: 100%;
}

.service-box2:hover > .service-box-title-h1 {
  color: var(--clr-text-red);
}

.service-box2 {
  padding: clamp(2rem, 5vw, 3rem) clamp(2rem, 5vw, 3rem) clamp(2rem, 5vw, 3rem)
    clamp(2rem, 5vw, 3rem);
  width: min(380px, 90vw);
  background-color: var(--clr-card-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  border-radius: 7px;
}

.service-box3 {
  padding: clamp(2rem, 5vw, 3rem) clamp(2rem, 5vw, 3rem) clamp(2rem, 5vw, 3rem)
    clamp(2rem, 5vw, 3rem);
  width: min(380px, 90vw);
  background-color: var(--clr-card-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 7px;
}

.service-box3:hover,
.service-box2:hover,
.service-box1:hover {
  transform: scale(1.05);
}

.service-box-container-2 {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.service-title-p {
  background: var(--gradient-blue-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  font-size: var(--fs-m-v2);
}

.service-title-h1 {
  color: var(--clr-text-white);
  font-size: var(--fs-xl-v2);
  width: 100px;
  margin-bottom: 24px;
}

.lighter {
  font-weight: var(--fw-thin);
}

.service-box-title-p {
  font-size: var(--fs-s);
}

.service-box-title-h1 {
  align-self: flex-start;
  font-size: var(--fs-m-v3);
  transition: all 0.3s;
}

.service-box-title-h1:hover {
  color: var(--clr-text-white);
}

.service-box-title-h1:hover .service-box-title-highlight-3,
.service-box3:hover .service-box-title-h1 {
  color: var(--clr-logo-blue);
}

.service-box-title-highlight-1 {
  background: var(--gradient-white-purple-blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: var(--fw-thin);
}

.service-box-title-highlight-2 {
  background: var(--gradient-white-yellow-red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: var(--fw-thin);
}

.service-box-title-highlight-3 {
  font-weight: var(--fw-thin);
  transition: all 0.3s;
}

.service-box-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 1rem;
  list-style-position: inside;
}

.service-box-list > li {
  transition: all 0.3s;
  padding: 2px 0px;
  font-weight: var(--fw-bold);
  cursor: pointer;
}

.service-box-list > li::before {
  content: ">";
  margin-right: 10px;
  font-size: 80%;
  background-color: var(--clr-text-white);
  color: var(--clr-text-black);
  font-weight: var(--fw-bold);
  padding: 2px 6px;
  border-radius: 3px;
}

.service-box-list > li:hover {
  background-color: var(--clr-text-white);
  color: var(--clr-text-black);
  font-weight: var(--fw-bold);
  padding: 2px 0px;
  border-radius: 3px;
}

.service-box-list > h3 {
  font-weight: var(--fw-thin);
  border-bottom: 1px solid white;
  line-height: 35px;
  width: fit-content;
  transition: all 0.3s;
}

.service-box-list > h3:hover {
  transform: scale(1.1);
  border-bottom: 1px solid transparent;
  color: var(--clr-logo-blue);
}

.addon-btn {
  align-self: flex-start;
}

.service-box-list-item {
  font-weight: var(--fw-thin);
  font-size: var(--fs-s);
}

.service-btn {
  width: 10px;
  height: 10px;
  background-color: var(--clr-bg-white);
  border-radius: 50%;
  transition: all 0.3s;
}

@media (max-width: 1530px) {
  .service-text-container {
    position: relative;

    left: 540px;
  }

  .service-box1 {
    margin-top: 250px;
  }

  .service-boxes {
    margin-top: 200px;
    margin-right: 500px;
  }
}

@media (max-width: 825px) {
  .services-container {
    flex-direction: column;
    gap: 0;
  }

  .service-text-container {
    position: relative;
    top: 0px;
    left: 0px;
  }

  .service-boxes {
    flex-direction: column;
    margin-right: 0;
    margin-top: 50px;
  }
}
