#testimonials {
  background-color: var(--clr-bg-white);
}

.testimonials-container {
  padding: 50px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.testimonials-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: clamp(350px, 50vw, 700px);
}

.testimonials-header > h3 {
  background: var(--gradient-black-orange);
  background-clip: text;
  color: transparent;
}

.testimonials-header > h2 {
  font-size: var(--fs-l);
}

.testimonials-header > h3 {
  font-size: var(--fs-m-v2);
}

.testimonials-wrapper {
  position: relative;
  width: 100%;
}

.testimonials {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
}

.testimonials::-webkit-scrollbar {
  display: none;
}

.testimonials-background {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  width: 700px;
  height: 350px;
  background: var(--gradient-testimonials);
  opacity: 0.3;
}

.testimonials-buttons {
  display: none;
  gap: 0.5rem;
}

.back-btn,
.forward-btn {
  transition: all 0.3s;
  cursor: pointer;
}

.back-btn {
  transform: rotate(180deg);
}

.back-btn:hover,
.forward-btn:hover {
  opacity: 0.8;
}

.testimonials-leave-review {
  align-self: center;
}

.leave-review {
  border-bottom: 1px solid black;
  transition: all 0.3s;
  width: fit-content;
  &:hover {
    color: var(--clr-logo-blue);
    border-bottom: 1px solid var(--clr-logo-blue);

    cursor: pointer;
  }
}

@media (max-width: 1358px) {
  .testimonials {
    justify-content: flex-start;
    max-width: none;
  }
}

@media (min-width: 1400px) {
  .testimonials {
    justify-content: center;
  }

  .testimonials-background {
    width: 700px;
    height: 350px;
  }
}

@media (max-width: 767px) {
  .testimonials-container {
    padding: 50px 0px 50px 1rem;
    gap: 2rem;
    align-items: flex-start;
  }
  .testimonials-leave-review {
    align-self: flex-start;
    margin-left: 1rem;
  }

  .testimonials-leave-review > p {
    display: flex;
    flex-direction: column;
  }

  .testimonials-buttons {
    display: flex;
    padding-left: 1.1rem;
  }

  .testimonials-header {
    padding: 0 0 0 1rem;
    text-align: left;
    align-items: flex-start;
  }
  .testimonials {
    justify-content: flex-start;
    max-width: none;
  }

  .testimonials-background {
    width: 100%;
    height: 200px;
    filter: blur(20px);
  }
}

@media (max-width: 1154px) {
  .testimonials {
    padding: 0px 0 0px 1rem;
  }

  .testimonials-background {
    left: 0;
  }
}
