* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Red Hat Display", sans-serif;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
}

/* Wrap section as the main tag in each component followed by the divs for Container and Row */
section {
  width: 100%;
}

.App {
  overflow-x: hidden;
}

/* For wrapping up each component using <div> */
.container {
  padding: 16px 0;
}

.row {
  width: 100%;
  margin: 0 auto;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Text Logo Styling */
.logo {
  font-size: 32px;
  font-weight: 700;
  color: var(--clr-logo-white);
}

.logo-touch {
  font-size: 24px;
  font-weight: 400;
  color: var(--clr-logo-blue);
}
.up-icon {
  width: 70px;
  height: 70px;
  z-index: 10000;
  position: fixed;
  right: 40px;
  bottom: 30px;
  cursor: pointer;
  background-color: gray;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
