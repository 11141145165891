.addon-content {
  align-items: center;
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  gap: 5rem;
}

.addon-pricing {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: min(5rem, 5vw);
}

.addon-price > h1::before {
  content: "$";
}

.addons > div:nth-child(1) {
  margin-left: -50px;
}

.addons > div:nth-child(2) {
  margin-left: 100px;
}
.addons > div:nth-child(3) {
  margin-left: 250px;
}

.line {
  display: none;
}

.addon-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  color: var(--clr-text-white);
  padding: 1rem 2rem;
  background: black;
  border-radius: 3px;
  box-shadow: 2px 3px 10px 4px rgba(20, 20, 20, 0.4);
  transition: transform 0.3s;
}

.addon-card:first-child {
  background: linear-gradient(135deg, #5e2563 50%, #65789b 100%);
  background-size: 200% 100%;
  background-position: 100% 0;
}

.addon-card:nth-child(2) {
  background: linear-gradient(135deg, #4d12bc 50%, #17dbfe 100%);
  background-size: 200% 100%;
  background-position: 100% 0;
}

.addon-card:nth-child(2):hover {
  animation: gradientflare 0.7s forwards linear;
}

.addon-card:first-child:hover {
  animation: gradientflare 0.7s forwards linear;
}

.addon-card:last-child {
  background: linear-gradient(135deg, #ff4b2b 50%, #ff9851 100%);
  background-size: 200% 100%;
  background-position: 100% 0;
}

.addon-card:last-child:hover {
  animation: gradientflare 0.7s forwards linear;
}

@keyframes gradientflare {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.addon-header > h1 {
  font-size: calc(var(--fs-l) - 0.5rem);
}

.addon-features {
  list-style: inside;
  display: flex;
  flex-direction: column;
}

.addon-features > div > li {
  list-style-type: none;
  transition: all 0.3s;
  padding: 5px 5px 5px 0px;
}

.addon-features > div > li:hover {
  background-color: white;
  color: black;
  border-radius: 5px;
}

.addon-features > div > li::before {
  content: "> ";
  color: black;
  background-color: white;
  margin: 5px;
  font-weight: 900;
  font-size: 0.8rem;
  padding: 2px 4px 2px 6px;
  border-radius: 2px;
}

@media (max-width: 1260px) {
  .addons > div:nth-child(1),
  .addons > div:nth-child(2),
  .addons > div:nth-child(3),
  .addons > div:nth-child(4) {
    margin-left: 0px;
  }

  .addon-card {
    justify-content: center;
    padding: 2rem;
    gap: 2rem;
    width: clamp(350px, 30vw, 900px);
  }

  .addon-content {
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .addon-card {
    background-color: var(--clr-card-white);
    box-shadow:
      0 0px 75px 0 rgba(0, 76, 255, 0.3),
      25px 0px 75px 0px rgba(2, 248, 80, 0.2),
      0px 0px 75px 25px rgba(164, 2, 2, 0.1);
  }

  .addon-header > h1 {
    font-size: calc(var(--fs-l) + 0.3rem);
  }

  .addon-content {
    align-items: center;
    justify-content: center;
  }

  .addon-card {
    width: calc(100% - 1rem);
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .line {
    display: block;
    width: 100%;
    border-bottom: 1px solid black;
  }
}
