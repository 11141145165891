@import url("https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap");

#about-us {
  background-color: var(--clr-bg-black);
}

.aboutus-container {
  width: 100%;
  max-width: 1200px;
  padding: 50px 0px 0px 0px;
  position: relative;
  overflow: hidden;
}

.aboutus-header > h1 {
  text-align: center;
  color: var(--clr-text-white);
  padding: 0 1rem;
  font-size: var(--fs-l);
}

.aboutus-content {
  width: 100%;
  color: var(--clr-text-white);
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadein {
  animation: 1s fadein;
}

/* 768px: Tablets & Ipads */
@media (max-width: 768px) {
  .aboutus-tabs {
    padding: 1;
  }
}
