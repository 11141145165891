.results {
  background-color: var(--clr-bg-white);
  display: flex; /* Use flexbox to center the content */
  justify-content: start;
  align-items: start;
  color: var(--clr-text-white);
  gap: 2rem;
  overflow: hidden;
  width: 100%;
  max-height: 850px;
}

.results > img {
  width: 100%;
  background-color: hsla(0, 0%, 100%, 1);
}

.swiper-project {
  width: 95%;
}

@media (max-width: 1200px) {
  .results {
    padding: 50px min(0vw, 15rem, 200px) 0 min(0vw, 15rem, 200px);
  }
}

@media (max-width: 768px) {
  .results {
    padding: 5px 0 0 0;
  }
  .swiper-project {
    width: 90%;
  }
}

.results-heading {
  display: grid;
  gap: 2rem;
  text-align: center;
  padding: 2rem 0rem;
  width: 100%;
  background-color: var(--clr-bg-black);
}

.results-heading-top {
  display: grid;
  gap: 1rem;
}

.results-heading-top > h1 {
  font-weight: var(--fw-bold);
  font-size: var(--fs-l);
}

.results-heading-top,
.results-cta > p {
  font-size: var(--fs-s);
  font-weight: var(--fw-thin);
}
.project > img {
  width: clamp(250px, 50vw, 400px);
}

.results-cta {
  display: grid;
  place-items: center;
  gap: 1rem;
}

.slides {
  display: grid;
  place-items: center;
  gap: 1.5rem;
  position: relative;
  width: fit-content;
}
