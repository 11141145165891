footer {
  background-color: var(--clr-bg-black);
  color: var(--clr-text-white);
}

.footer-content {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
}

.footer-title {
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  text-align: center;
  margin: 24px 0 8px;
}

.footer-para {
  font-size: calc(var(--fs-s) + 2px);
  font-weight: var(--fw-mid);
  text-align: center;
  margin-bottom: 72px;
}

.footer-links-list {
  margin-bottom: 72px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.footer-links {
  width: 15%;
  display: flex;
  flex-direction: column;
}

.footer-border-mobile {
  display: none;
}

.footer-links-title {
  font-size: calc(var(--fs-s) - 2px);
  font-weight: var(--fw-bold);
  margin-bottom: 4px;
}

.footer-links-sublist {
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.footer-link {
  font-size: calc(var(--fs-s) - 1px);
  font-weight: var(--fw-mid);
  margin-top: 16px;
}

.footer-link-anchor {
  color: var(--clr-text-white);
  text-decoration: none;
}

.footer-content-bottom {
  width: 80%;
  margin: 0 auto;
  padding: 32px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  font-size: calc(var(--fs-s) - 4px);
  text-align: center;
}

.footer-social-links {
  list-style-type: none;
  display: flex;
  gap: 24px;
  align-items: center;
}

.footer-social-link-anchor {
  color: var(--clr-text-white);
  font-size: 20px;
  border: 2px solid var(--clr-text-white);
  border-radius: 100%;
  padding: 8px 13px;
}

.footer-border {
  margin: 0 auto;
  width: 85%;
}

/* RESPONSIVENESS */
/* (1200px +) : Large Desktops (WORKS BY DEFAULT) */

/* 992px: Desktops */

/* 768px: Tablets */
@media (max-width: 768px) {
  .footer-links-list {
    margin-bottom: 8px;
    flex-direction: column;
    gap: 24px;
  }

  .footer-links {
    width: 100%;
  }

  .footer-title-underline {
    display: none;
  }

  .footer-border-mobile {
    display: block;
    width: 100%;
  }

  .footer-border {
    display: none;
  }

  .footer-content-bottom {
    width: 100%;
    padding: 24px 0;
  }

  .footer-social-link-anchor {
    font-size: 16px;
    padding: 8px 12px;
  }
}

/* 576px: Mobile Phones */
@media (max-width: 576px) {
  .footer-content-bottom {
    flex-direction: column;
    gap: 24px;
  }

  .footer-social-links {
    margin-top: 16px;
  }
}

/* 367px: Small Mobile Phones */
