.book-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.ant-picker {
  width: 100%;
}

.book-form-flex {
  display: flex;
  flex-direction: column;
  animation: fadein 0.5s forwards;
}

.book-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.book-buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  align-items: center;
  justify-content: end;
}

.book-form-success {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.sucess-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.book-calendar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.book-calendar > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.book-calendar > div > label {
  font-size: 2rem;
}

.sucess-message > :nth-child(3) {
  margin-top: 2rem;
}

.sucess-message > * {
  animation: fadein 0.7s forwards;
}

.book-btn {
  min-width: 150px;
  align-self: flex-end;
  &:hover {
    cursor: pointer;
  }
}

.form-reset {
  color: darkred;
  border-bottom: 1px solid darkred;
}

.form-error {
  color: red;
  font-weight: thin;
  grid-column-start: 2;
  margin-top: 5px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;

  overflow: hidden;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media (max-width: 768px) {
  .book-modal {
    grid-template-columns: 1fr;
  }

  .book-calendar {
    flex-direction: column-reverse;
    order: 1;
  }

  .form-error {
    grid-column-start: 1;
  }
}
