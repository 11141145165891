.aboutus-tabs :first-child {
  border-right: none;
  border-radius: 10px 0 0 10px;
}

.aboutus-tabs :last-child {
  border-left: none;
  border-radius: 0 10px 10px 0;
}

.aboutus-tabs > * {
  width: clamp(100px, 20vw, 170px);
  height: clamp(30px, 15vw, 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 5px;
  border: 1px solid white;
  cursor: pointer;
  transition: all 0.3s;
}

.aboutus-tabs > *:hover,
.active-tab {
  background-color: #666666;
}

.aboutus-tabs {
  padding-top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
}

@media (max-width: 768px) {
  .aboutus-tabs {
    padding: 1;
  }
}
