.modal-body {
  color: white;
  overflow: auto;
}
.title {
  color: black;
}
.Modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Modal-head > h2 {
  font-weight: 800;
  font-size: 1.6rem;
}

.close-icon {
  width: 25px;
  transition: transform 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}
button {
  border: none;
  background: transparent;
}
.center-modal {
  margin: 100px;
}
