.workflow-card {
  width: clamp(350px, 20vw 400px);
  min-height: 250px;
  max-height: 250px;
  padding: 4rem 6rem;
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 10px 5px 20px 0px rgba(0, 0, 0, 0.25);
}

.workflow-show-content {
  display: flex;
  align-items: center;
  opacity: 1;
  animation: 1s fadein;
  color: var(--clr-text-white);
}

.workflow-show-content > h2 {
  white-space: nowrap;
  font-size: var(--fs-m-v3);
  font-weight: var(--fw-bold);
}

.workflow-show-content > p {
  font-size: var(--fs-s);
  font-weight: var(--fw-thin);
}

.workflow-hidden-content {
  display: none;
  animation: 1s fadein forwards;
}

.workflow-hidden-content > h2 {
  font-size: var(--fs-m-v3);
  font-weight: var(--fw-mid);
}

.workflow-hidden-content > p {
  font-size: var(--fs-s);
  width: 100%;
  font-weight: var(--fw-thin);
}

.workflow-card:hover .workflow-hidden-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: var(--clr-text-white);
}

.workflow-card:hover .workflow-show-content {
  display: none;
}

.workflow-card-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  top: 0;
  right: 0;
  background-color: rgb(68, 68, 68);
  position: absolute;
  border-radius: 0 0 0 1.5rem;
  color: white;
}

.workflow-card-circle > p {
  transform: rotate(3deg);
  transition: all 0.3s;
}

.workflow-card::before {
  content: "";
  position: absolute;
  z-index: 0;
  background: rgb(68, 68, 68);
  top: -14px;
  right: -14px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 1s ease-out;
}

.workflow-card:hover:before {
  transform: scale(75);
}

.workflow-card:hover .workflow-card-circle > p {
  opacity: 0;
}

.workflow-card-img {
  position: absolute;
  width: 100%;
  top: -150px;
  opacity: 0.1;
}

.workflow-card-img > img {
  width: 100%;
  aspect-ratio: 1 / 1;
}

@media screen and (max-width: 1150px) {
  .workflow-show-content {
    flex-direction: column;
  }

  .workflow-card {
    padding: 4rem 3rem;
  }

  .workflow-show-content {
    gap: 1rem;
  }

  .workflow-show-content > h2 {
    text-align: center;
  }

  .workflow-show-content > p {
    text-align: center;
  }
}
