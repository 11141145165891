.Delivery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-bg-white);
  margin: 50px 10rem;
  gap: 2rem;
  height: auto;
}

.Delivery-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Delivery-title-p-1 {
  font-size: var(--fs-s);
  background: var(--gradient-blue-purple);
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Delivery-title-h1-1 {
  font-size: var(--fs-l);
  font-weight: var(--fw-thin);
}

.Delivery-title-h1-2 {
  font-size: var(--fs-l);
}

.Delivery-title-p-2 {
  font-size: var(--fs-s);
  font-weight: var(--fw-thin);
}

.workflow-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(350px, 400px));
  align-content: center;
  justify-content: center;
  place-content: center;
  grid-gap: 2rem;
}

.workflow-grid :nth-child(1) {
  grid-column: 1 / span 2;
}

.workflow-grid :nth-child(2) {
  grid-column: 3 / span 1;
}

.workflow-grid :nth-child(3) {
  grid-column: span 1;
}

.workflow-grid :nth-child(4) {
  grid-column: span 2;
}

/* Responsive adjustments */
@media screen and (max-width: 1150px) {
  .Delivery-container {
    margin: 50px 3rem;
  }

  .workflow-grid {
    grid-template-columns: repeat(1, minmax(350px, 1fr));
  }

  .workflow-grid :nth-child(1),
  .workflow-grid :nth-child(2),
  .workflow-grid :nth-child(3),
  .workflow-grid :nth-child(4) {
    grid-column: span 1;
  }
}

@media screen and (max-width: 768px) {
  .Delivery-container {
    margin: 50px 1rem;
  }

  .workflow-grid {
    grid-template-columns: repeat(1, minmax(350px, 1fr));
  }

  .workflow-grid :nth-child(1),
  .workflow-grid :nth-child(2),
  .workflow-grid :nth-child(3),
  .workflow-grid :nth-child(4) {
    grid-column: span 1;
  }
}
